.admin-settings {

  &-section {
    max-width: 1200px;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 24px;

      &-title{
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 1.1;
        color: $black;
      }

      &-description {
        font-family: 'SFPro-Text', sans-serif;
        text-align: justify;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5;
        max-width: 800px;
        color: $dark-gray;
        padding-bottom: 1.5em;
      }
    }
  }

  &__box {
    border: 1px solid $lighter-gray;
    border-radius: 8px;

    & + .admin-settings-section__header {
      margin-top: 48px;
    }
  }

  &__element{
    padding-left: 24px;
    padding-right: 18px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    min-height: 56px;
    justify-content: space-between;
    border-top: 1px solid $lighter-gray;

    &:first-child{
      border-top: none !important;
    }

    &-textinput{
      width: 100%;
      margin: 0;
    }
    .select-box {
      padding-top: 16px;
    }

    // Toggles
    &-open {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__container {
      border-top: 1px solid $lighter-gray;
      width: 100%;
      margin-top: 32px;
      padding-top: 26px;
      padding-left: 40px;
      padding-right: 40px;

      & > .toggle__wrapper {
        padding-bottom: 16px;
      }
    }
  }

  &-subnav {
    position: sticky;
    top: 0;

    &.ui.menu.vertical {
      border: 0;
      border-radius: 0;
      box-shadow: none;

      .item {
        display: block;
        font-family: 'SFPro-Text', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-align: left;
        color: $black;
        background-color: transparent !important;
        border: 0;
        padding: 1em;
        margin: 0;

        &.active {
          font-weight: 700;

          &:after {
            font-family: Icons;
            font-size: 0.875em;
            font-weight: 400;
            content: "\f054";
            position: absolute;
            right: 0;
          }
        }
      }
    }

  }

  @include on-medium-device {
    // semantic ui is very specific...
    .ui.grid > div.column {
      width: 100% !important;
    }

    .ui.menu.vertical {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 1px solid $lighter-gray;
      margin-bottom: 2rem;

      > .item {
        border-radius: 0 !important;

        &:before { content: none; }
        &.active {
          border-bottom: 2px solid $main-green;
          &:after { content: none; }
        }
      }
    }
  }
}
