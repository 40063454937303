@import './shared.scss';
@import './App.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import '../components/shared/Button/Button.scss';
@import '../components/shared/RoundedIcon/RoundedIcon.scss';
@import '../components/shared/TriangleButton/TriangleButton.scss';
@import '../components/shared/CustomDatePicker/CustomDatePicker.scss';
@import '../components/login/SplitView/SplitView.scss';
@import '../components/login/FullImage/FullImage.scss';
@import '../components/login/Container/Container.scss';
@import '../components/login/FormContainer/FormContainer.scss';
@import '../components/login/LinkRef/LinkRef.scss';
@import '../components/shared/TextInput/TextInput.scss';
@import '../components/shared/ColorPicker/ColorPicker.scss';
@import '../components/shared/CustomTextArea/CustomTextArea.scss';
@import '../components/shared/PasswordInput/PasswordInput.scss';
@import '../components/shared/SearchBar/SearchBar.scss';
@import '../components/dashboard/MessagesContainer/MessagesContainer.scss';
@import '../components/dashboard/MessageForm/MessageForm.scss';
@import '../components/dashboard/MessageForm/MessageCustomSelect/MessageCustomSelect.scss';
@import '../components/dashboard/MessageElement/MessageElement.scss';
@import '../components/dashboard/ReportsContainer/ReportsContainer.scss';
@import '../components/dashboard/AdminContainer/AdminContainer.scss';
@import '../components/dashboard/AnalyticsContainer/AnalyticsContainer.scss';
@import '../components/dashboard/UserContainer/UserContainer.scss';
@import '../components/dashboard/AdminTabs/AdminTabs.scss';
@import '../components/dashboard/AdminSettings/AdminSettings.scss';
@import '../components/dashboard/AdminSettings/AdminLanguages/AdminLanguages.scss';
@import '../components/dashboard/AdminSettings/AdminMap/AdminMap.scss';
@import '../components/dashboard/AdminSettings/AdminAppSettings/AdminAppSettings.scss';
@import '../components/dashboard/AdminSettings/AdminPublicSiteSettings/AdminPublicSiteSettings.scss';
@import '../components/dashboard/AdminLayerContent/AdminLayerContent.scss';
@import '../components/dashboard/AdminTags/AdminTagGroupContainer.scss';
@import '../components/dashboard/AdminTags/forms/AdminTagsGroupForm.scss';
@import '../components/dashboard/AdminTags/tagElement/AdminTagElement.scss';
@import '../components/dashboard/AdminExportReport/AdminExportReport.scss';
@import '../components/dashboard/DashboardHoc/DashboardHoc.scss';
@import '../components/dashboard/NavigationBar/NavigationBar.scss';
@import '../components/dashboard/CreateReport/CreateReport.scss';
@import '../components/dashboard/ReportInfo/ReportInfo.scss';
@import '../components/dashboard/FiltersPanel/FiltersPanel.scss';
@import '../components/dashboard/FolderElement/FolderElement.scss';
@import '../components/dashboard/AdminUsersList/AdminUserListContent';
@import '../components/dashboard/AdminOrganizationsList/AdminOrganizationContent';
@import '../components/dashboard/AdminModal/AdminModal.scss';
@import '../components/dashboard/DeleteModal/DeleteModal.scss';
@import '../components/dashboard/ReportHeader/ReportHeader.scss';
@import '../components/dashboard/ReportHeader/SearchReports/SearchReports';
@import '../components/dashboard/ReportsMap/ReportsMap.scss';
@import '../components/dashboard/ReportZip/ReportZip.scss';
@import '../components/dashboard/ReportView/ReportView';
@import '../components/dashboard/ReportView/ReportBasicInfo/ReportBasicInfo.scss';
@import '../components/dashboard/ReportView/ReportUrgencyBar/ReportUrgencyBar.scss';
@import '../components/dashboard/ReportView/ReportCategories/ReportCategories.scss';
@import '../components/dashboard/ReportView/ReportMedia/ReportMedia.scss';
@import '../components/dashboard/ReportView/ReportImageSlider/ReportImageSlider.scss';
@import '../components/dashboard/ReportView/ReportMap/ReportMap.scss';
@import '../components/dashboard/ReportActivityFeed/ReportActivityFeed.scss';
@import '../components/dashboard/StoriesContainer/StoriesContainer.scss';
@import '../components/dashboard/StoriesContainer/StoryElement/StoryElement.scss';
@import '../components/dashboard/StoriesContainer/StoryCreate/StoryCreate.scss';
@import '../components/dashboard/StoriesContainer/StoryReport/StoryReport.scss';
@import '../components/dashboard/StoryInfoContainer/StoryInfoContainer.scss';
@import '../components/dashboard/StoryInfoContainer/StoryInfo/StoryInfo.scss';
@import '../components/ui/CustomCheckbox/CustomCheckbox.scss';
@import '../components/ui/Loading/Loading.scss';
@import '../components/ui/FullHeader/FullHeader.scss';
@import '../components/ui/AlertMessage/AlertMessage.scss';
@import '../components/ui/UploadedFile/UploadedFile.scss';
@import '../components/ui/SelectBox/SelectBox.scss';
@import '../components/ui/Collapsible/Collapsible.scss';
@import '../components/ui/Toggle/Toggle.scss';
@import '../components/ui/CustomRadio/CustomRadio.scss';
@import '../components/NotFound/NotFound.scss';

html {
  font-size: 4vw;

  $px_size: 14;
  $rem_size: $px_size/16;
  $vw_size: $px_size * 0.9/1600 * 100;

  // 14px from 992+
  @media screen and (min-width: 992px) {
    font-size: #{$rem_size}rem !important;
  }

  // variable ratio from 1200+
  @media screen and (min-width: 1200px) {
    font-size: #{'max(#{$px_size+'px'}, #{$vw_size+'vw'})'} !important;
  }
}

body {
  font-size: inherit !important;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  *:focus{
    outline-color: transparent;
    outline-style: none;
    outline-width: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.defaultscrollbar {
  &::-webkit-scrollbar {
    width: 0.375rem;
    height: 0;
    border-radius: 0;
  }
  &::-webkit-scrollbar-track{
    background-color: $white;
    width: 80px;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    max-height: 40px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.375rem;
    height: 0;
    background: $smooth-gray;
    border-radius: 100px;
  }
}

.whitepagescrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 0;
    height: 0;
  }
  &::-webkit-scrollbar-track{
    background-color: $white;
    width: 80px;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    max-height: 40px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    height: 0;
    background: $smooth-gray;
    border-radius: 100px;
  }
}

.grayscrollbar{
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0;
    border-radius: 0;
  }
  &::-webkit-scrollbar-track{
    background-color: $lightest-gray;
    width: 80px;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    max-height: 40px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    height: 0;
    background: $smooth-gray;
    border-radius: 100px;
  }
}
