.toggle__wrapper{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.toggle__label{
  &-inverse{
    margin-left: 8px;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: $darkest-gray;
  }
}

.ui.checked.fitted.toggle.checkbox.toggle__element > label:before
{
  background-color: $lighter-green !important;
}
