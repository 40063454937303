.admin_tagsgroups-form{
  &__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 30px;

  }

  &__inputfields{
    > .text-input__wrapper:last-child {
      margin-bottom: 56px;
    }
  }

  &__radiogroup{
    display: flex;
    margin-bottom: 48px;
    margin-right: 75px;
    justify-content: space-between;
  }

  &__buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > .button__wrapper {
      margin-right: 15px;
      margin-left: 15px;
      height: 48px;
      width: 160px;
    }
  }
}