.select-box{
  width: 100%;
  margin-bottom: 1.25rem;
  text-align: left;
}

.select-box__wrapper, .ui.search.dropdown{
  width: 100%;
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  color: $darker-gray;
  border-radius: 6px;
  border: 1px solid $lighter-gray;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 2.125rem !important;
  margin-bottom: 0.375rem;
  padding: 0.5em 1em !important;

  &--medium{
    width: 256px !important;
    height: 40px !important;
    font-weight: bold !important;
    border: 1px solid $lightest-gray !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  }
  &--small{
    width: 214px !important;
    height: 40px !important;
    font-weight: bold !important;
    border: 1px solid $lightest-gray !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  }
  &--errors{
    border: 1px solid $system-red !important;
  }
}

.select-box__wrapper.ui.selection.active.dropdown,
.select-box__wrapper.ui.selection.active.dropdown:hover,
.select-box__wrapper.ui.selection.active.dropdown:focus,
.select-box__wrapper.ui.selection.dropdown:focus,
.select-box__wrapper.ui.selection.dropdown:hover,
.select-box__wrapper.ui.selection.active.dropdown .menu,
.select-box__wrapper.ui.selection.active.dropdown:hover .menu{
  border-color: $main-green;
}

.select-box__wrapper.ui.selection.dropdown > .icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.ui.default.dropdown:not(.button)>.text,
.ui.dropdown:not(.button)>.default.text,
.ui.selection.dropdown{
  font-family: 'SFPro-Text', sans-serif;
  font-weight: normal;
  font-size: 1rem !important;
  color: $smooth-gray;
}
