.admin-settings-lessons {
  display: flex;
  justify-content: space-between;

  &.admin-settings__element-open {
    .text-input__wrapper {
      margin-top: 24px;
    }
  }

  .admin-settings__element {
    &-title {
      font-family: 'SFPro-Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5;
      color: $black;
    }
    &-radio-groups {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & > .custom-radio__wrapper{
        margin-right: 50px;
        @include on-medium-device{
          margin-right: 25px;
        }
        & label {
          &::before{
            top: -1px !important;
          }
          &::after{
            top: -1px !important;
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

.admin-settings-emergency-contact {
  border-top: 1px solid $lighter-gray;
  width: 100%;
  margin-top: 16px;
  padding: 0 40px;

  &__title {
    font-family: 'SFPro-Text', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: $darker-gray;
    text-align: left;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .message-form-userselect { padding-top: 8px; }

  &__phone {
    display: flex;
    align-items: stretch;

    .select-box {
      width: 125px;
      margin: 0 8px 0 0;
      padding-top: 0;

      .dropdown { height: 100%; }
    }

    .text-input__wrapper,
    .text-input__input {
      margin: 0;
    }
  }

  &-elem-copy {
    font-family: 'SFPro-Text', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .add-link {
      color: #16675d; // @fixme
      font-weight: 700;
      cursor: pointer;
      &:hover { text-decoration: underline; }
    }

    > * { margin-top: 24px; }
  }
}
