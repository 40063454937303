.message-form {
  &-userselect{
    padding-top: 2rem;

    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;

    &-searchinput{
      .select-box__wrapper{
        min-height: 4.5rem !important;
        align-items: center;

        .default.text{
          padding-left: 2.5rem;
          top: 0.75rem;

          &::before {
            z-index: 10;
            color: $darker-gray;
            content: url('../../../../assets/images/search.svg');
            position: absolute;
            top: 0.25rem;
            left: 0.75rem;
          }
        }
      }
      .ui.search.dropdown {

        & > input.search{
          background: #F2F2F2 !important;
          border-radius: 0.5rem;
          box-shadow: none!important;
          cursor: text;
          top: 0.75rem;
          left: 1rem;
          font-size: 1rem;
          width: calc(100% - 2rem);
          height: 3.325rem !important;
          padding: 0.5rem 1rem !important;
          line-height: 2rem !important;
          margin: auto;
          outline: 0;
          -webkit-tap-highlight-color: rgba(255,255,255,0);

          &[value=""] {
            padding-left: 2.5rem !important;
          }
        }
      }
    }

    &-results{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 1rem;

      &-elem{
        padding: 0.25rem 0.5rem;
        min-width: 147px;
        max-width: 220px;
        min-height: 1.5rem;
        align-items: center;
        font-size: 1rem;
        font-family: 'SFPro-Text', sans-serif;
        font-weight: bold;
        text-align: center;
        justify-content: space-between;
        border-radius: 4px;
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;
        display: flex;
        & > span {
          width: 100%;
        }
        &-closeicon{
          cursor: pointer;
        }
      }
    }
  }
}
