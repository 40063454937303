.user-list-content{
  &__wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;

    & > .text-input__wrapper{
      width: 40%;
      padding-left: 40px;
      margin-right: 10px;
    }

    & > .select-box{
      width: 40%;
      padding-left: 40px;
      margin-right: 10px;
    }

    &--create > .text-input__wrapper{
      width: 45%;
      padding-left: 40px;
      margin-right: 10px;
    }

    &--create > .select-box{
      width: 45%;
      padding-left: 40px;
      margin-right: 10px;
    }
  }

  &__actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 380px;
    padding-left: 40px;

    &-createbutton{
      margin-right: 34px;
      & > .button__wrapper {
        width: 146px;
        margin: 0;
      }
    }


    &--create{
      width: 200px;
    }
  }

}
